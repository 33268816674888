import React from "react";

export default function IntegrationButton({
  handleShowCode,
  showCode,
  language,
  icon,
  title,
}) {
  return (
    <>
      <button
        className={`flex items-center shadow-card py-3 px-6 rounded whitespace-nowrap hover:bg-primary group hover:text-white transition ${
          showCode === language ? "bg-primary" : "bg-white"
        } `}
        onClick={() => handleShowCode(language)}
      >
        {icon && (
          <span
            className={`pr-2 group-hover:text-white transition ${
              showCode === language ? "text-white" : "text-primary"
            }`}
          >
            {icon}
          </span>
        )}
        <span
          className={`font-medium text-xl capitalize group-hover:text-white transition ${
            showCode === language ? "text-white" : "text-black"
          }`}
        >
          {title}
        </span>
      </button>
    </>
  );
}
