import React from "react";
import Accordion from "./Accordion";

const icon1 = (
  <svg
    viewBox="0 0 34 35"
    className="fill-current w-7 h-7 sm:w-8 sm:h-8 md:w-7 md:h-7 xl:w-8 xl:h-8"
  >
    <path d="M31.1667 30.1113H2.83337V4.61133H5.66671V27.278H8.50004V14.528H14.1667V27.278H17V8.86133H22.6667V27.278H25.5V20.1947H31.1667V30.1113Z" />
  </svg>
);

const icon2 = (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    className="fill-current w-7 h-7 sm:w-8 sm:h-8 md:w-7 md:h-7 xl:w-8 xl:h-8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16.2917 15.5833L25.33 23.1908L24.0834 23.4458L23.1767 23.6158C22.2842 23.8 21.7742 24.7917 22.1709 25.5992L22.5534 26.4208L24.48 30.5858L22.4684 31.5208L20.5417 27.37L20.1734 26.5483C19.7909 25.7125 18.7284 25.4575 18.02 26.0383L17.2975 26.605L16.2917 27.4125V15.5833ZM15.2434 12.3108C14.9578 12.3108 14.684 12.4243 14.4821 12.6262C14.2801 12.8281 14.1667 13.1019 14.1667 13.3875V29.6083C14.1667 30.2033 14.6484 30.685 15.2434 30.685C15.5125 30.685 15.7392 30.6 15.9234 30.4583L18.6292 28.2625L20.9809 33.3908C21.165 33.7733 21.5475 34 21.9584 34C22.1142 34 22.27 34 22.4259 33.8867L26.3359 32.0733C26.8742 31.8183 27.1292 31.1667 26.8459 30.6425L24.48 25.5L27.8942 24.8625C28.1209 24.7917 28.3334 24.6925 28.5034 24.4942C28.8859 24.0408 28.8292 23.375 28.3334 22.9642L15.9517 12.5517L15.9375 12.5658C15.7534 12.41 15.5125 12.3108 15.2434 12.3108ZM21.25 14.1667V11.3333H28.3334V14.1667H21.25ZM19.5925 6.74333L23.6017 2.73417L25.5992 4.73167L21.59 8.74083L19.5925 6.74333ZM14.1667 0H17V7.08333H14.1667V0ZM5.56754 20.7683L9.57671 16.7592L11.5742 18.7567L7.56504 22.7658L5.56754 20.7683ZM5.56754 4.73167L7.56504 2.73417L11.5742 6.74333L9.57671 8.74083L5.56754 4.73167ZM9.91671 14.1667H2.83337V11.3333H9.91671V14.1667Z" />
  </svg>
);

const icon3 = (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    className="fill-current w-7 h-7 sm:w-8 sm:h-8 md:w-7 md:h-7 xl:w-8 xl:h-8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M24.7917 21.9585C24.7917 23.531 23.5309 24.7918 21.9584 24.7918C20.3859 24.7918 19.1251 23.531 19.1251 21.9585C19.1251 20.386 20.4001 19.1252 21.9584 19.1252C23.5167 19.1252 24.7917 20.4002 24.7917 21.9585ZM12.0417 19.1252C10.4834 19.1252 9.20841 20.4002 9.20841 21.9585C9.20841 23.5168 10.4834 24.7918 12.0417 24.7918C13.6001 24.7918 14.8751 23.531 14.8751 21.9585C14.8751 20.386 13.6142 19.1252 12.0417 19.1252ZM32.5834 21.2502V25.5002C32.5834 26.2793 31.9459 26.9168 31.1667 26.9168H29.7501V28.3335C29.7501 29.906 28.4892 31.1668 26.9167 31.1668H7.08341C5.52508 31.1668 4.25008 29.906 4.25008 28.3335V26.9168H2.83341C2.05425 26.9168 1.41675 26.2793 1.41675 25.5002V21.2502C1.41675 20.471 2.05425 19.8335 2.83341 19.8335H4.25008C4.25008 14.351 8.68425 9.91683 14.1667 9.91683H15.5834V8.11766C14.7334 7.636 14.1667 6.71516 14.1667 5.66683C14.1667 4.1085 15.4417 2.8335 17.0001 2.8335C18.5584 2.8335 19.8334 4.1085 19.8334 5.66683C19.8334 6.71516 19.2667 7.636 18.4167 8.11766V9.91683H19.8334C25.3159 9.91683 29.7501 14.351 29.7501 19.8335H31.1667C31.9459 19.8335 32.5834 20.471 32.5834 21.2502ZM29.7501 22.6668H26.9167V19.8335C26.9167 15.9235 23.7434 12.7502 19.8334 12.7502H14.1667C10.2567 12.7502 7.08341 15.9235 7.08341 19.8335V22.6668H4.25008V24.0835H7.08341V28.3335H26.9167V24.0835H29.7501V22.6668Z" />
  </svg>
);

const icon4 = (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    className="fill-current w-7 h-7 sm:w-8 sm:h-8 md:w-7 md:h-7 xl:w-8 xl:h-8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.5 26.9821L23.4107 8.07143H11.6429V4.5H29.5V22.3571H25.9286V10.5893L7.01786 29.5L4.5 26.9821Z" />
  </svg>
);

const icon5 = (
  <svg
    viewBox="0 0 34 35"
    className="fill-current w-7 h-7 sm:w-8 sm:h-8 md:w-7 md:h-7 xl:w-8 xl:h-8"
  >
    <path d="M2.83331 17.3613C2.83331 24.4022 8.54248 30.1113 15.5833 30.1113C18.9691 30.1113 22.2133 28.7797 24.65 26.428L22.525 24.303C20.7258 26.2155 18.2183 27.278 15.5833 27.278C6.74331 27.278 2.32331 16.5963 8.57081 10.3488C14.8183 4.10133 25.5 8.53549 25.5 17.3613H21.25L26.9166 23.028H27.0583L32.5833 17.3613H28.3333C28.3333 10.3205 22.6241 4.61133 15.5833 4.61133C8.54248 4.61133 2.83331 10.3205 2.83331 17.3613Z" />
  </svg>
);

const icon6 = (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    className="fill-current w-7 h-7 sm:w-8 sm:h-8 md:w-7 md:h-7 xl:w-8 xl:h-8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.08341 2.8335H26.9167C27.2925 2.8335 27.6528 2.98275 27.9185 3.24843C28.1842 3.5141 28.3334 3.87444 28.3334 4.25016V18.4168C28.3334 18.7926 28.1842 19.1529 27.9185 19.4186C27.6528 19.6842 27.2925 19.8335 26.9167 19.8335H7.08341C6.70769 19.8335 6.34736 19.6842 6.08168 19.4186C5.816 19.1529 5.66675 18.7926 5.66675 18.4168V4.25016C5.66675 3.87444 5.816 3.5141 6.08168 3.24843C6.34736 2.98275 6.70769 2.8335 7.08341 2.8335ZM8.50008 5.66683V17.0002H25.5001V5.66683H8.50008ZM28.3334 24.0835C28.3334 24.4592 28.1842 24.8196 27.9185 25.0852C27.6528 25.3509 27.2925 25.5002 26.9167 25.5002H7.08341C6.70769 25.5002 6.34736 25.3509 6.08168 25.0852C5.816 24.8196 5.66675 24.4592 5.66675 24.0835V22.6668H28.3334V24.0835ZM28.3334 29.7502C28.3334 30.1259 28.1842 30.4862 27.9185 30.7519C27.6528 31.0176 27.2925 31.1668 26.9167 31.1668H7.08341C6.70769 31.1668 6.34736 31.0176 6.08168 30.7519C5.816 30.4862 5.66675 30.1259 5.66675 29.7502V28.3335H28.3334V29.7502Z" />
  </svg>
);

export default function FeatureAccordions() {
  return (
    <section className="bg-[#F5F8FF] pt-24 pb-[70px]">
      <div className="container">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full xl:w-1/3 px-4">
            <div className="flex flex-wrap -mx-4">
              <div className="w-full lg:w-1/2 xl:w-full px-4">
                <Accordion
                  icon={icon1}
                  title="Statistics and Data Export"
                  description="Visualize and export form statistics with submission data directly from user dashboard."
                />
              </div>
              <div className="w-full lg:w-1/2 xl:w-full px-4">
                <Accordion
                  icon={icon2}
                  title="No Coding Required"
                  description="FormBold designed in a way that, you no need any coding skills. Just paste endpoint on form and you're ready to go."
                />
              </div>
            </div>
          </div>
          <div className="w-full xl:w-1/3 px-4">
            <div className="flex flex-wrap -mx-4">
              <div className="w-full lg:w-1/2 xl:w-full px-4">
                <Accordion
                  icon={icon3}
                  title="Auto Responder"
                  description="Send an automated email response to form submitter email. It has so many use cases such as - informing working hours, submission confirmations, etc."
                />
              </div>
              <div className="w-full lg:w-1/2 xl:w-full px-4">
                <Accordion
                  icon={icon4}
                  title="Custom Redirect"
                  description="Redirect users to specific URL after successful form submissions."
                />
              </div>
            </div>
          </div>
          <div className="w-full xl:w-1/3 px-4">
            <div className="flex flex-wrap -mx-4">
              <div className="w-full lg:w-1/2 xl:w-full px-4">
                <Accordion
                  icon={icon5}
                  title="Recaptcha Integration"
                  description="Even though we have server-side spam filtering system, FormBold provides free Google Recaptha integration option for all forms."
                />
              </div>
              <div className="w-full lg:w-1/2 xl:w-full px-4">
                <Accordion
                  icon={icon6}
                  title="Free Form Templates"
                  description="We crafted tons of diverse and ready-to-use form templates, that you can use for different purposes. And of course will keep adding new form templates."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
