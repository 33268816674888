import React, { useState } from "react";

const Accordion = (props) => {
  const { icon, title, description } = props;

  const [isOpen, setIsOpen] = useState(false);
  const accordionHandler = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <div className="bg-white shadow-accordion rounded-lg mb-8">
        <button
          className={`p-7 transition-all duration-300 ease-in-out w-full flex items-center rounded-lg ${
            isOpen ? "bg-primary" : "bg-transparent"
          }`}
          onClick={accordionHandler}
        >
          <span
            className={`pr-2 transition-all ease-in-out duration-300 ${
              isOpen ? "text-white" : "text-primary"
            } `}
          >
            {icon}
          </span>
          <span
            className={`font-semibold text-base sm:text-xl md:text-lg xl:text-xl transition-all ease-in-out duration-300 ${
              isOpen ? "text-white" : "text-black"
            } `}
          >
            {title}
          </span>
        </button>

        <div
          className={`transition-all duration-300 overflow-hidden ease-in-out ${
            isOpen ? "max-h-screen" : "max-h-0"
          }`}
        >
          <p className="p-7 font-medium text-base text-body-color leading-relaxed">
            {description}
          </p>
        </div>
      </div>
    </>
  );
};

export default Accordion;
