import React from "react";

export default function SingleFeature({ singleFeature }) {
  const { icon, title, description } = singleFeature;
  return (
    <>
      <div className="w-full md:w-1/2 lg:w-1/3 px-4">
        <div className="py-8 px-7 sm:p-9 lg:px-6 xl:px-9 shadow-feature rounded-xl mb-9 transition-all hover:shadow-feature2">
          <div className="inline-block mx-auto mb-7">{icon}</div>

          <div>
            <h3 className="font-bold text-xl sm:text-2xl lg:text-xl xl:text-2xl text-black mb-4">
              {title}
            </h3>
            <p className="font-medium text-base text-body-color">
              {description}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
