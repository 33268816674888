import { Link } from "gatsby";
import React from "react";
import image from "./../images/template-section.svg";
import SectionTitle from "./SectionTitle";

export default function TemplateSection() {
  return (
    <section className="bg-[#F5F8FF] py-24">
      <div className="container">
        <div className="flex flex-wrap items-center -mx-4">
          <div className="w-full lg:w-1/2 px-4">
            <div className="mb-12 lg:mb-0">
              <SectionTitle
                title="Ready to use form templates"
                titleWidth="550"
                paragraph="Tons of free form templates to get started! we crafted all essential form templates that you can start using immediately without coding from scratch."
                paraWidth="570"
                margin="mb-8"
              />
              <div>
                <Link
                  to="/templates"
                  className="bg-primary btn inline-flex items-center justify-center py-4 px-9 hover:bg-opacity-90"
                >
                  Form Templates
                </Link>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/2 px-4">
            <div className="text-center">
              <img
                src={image}
                alt="template section image"
                className="max-w-full mx-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
