import * as React from "react";
import CallToAction from "../components/CallToAction";
import FeatureAccordions from "../components/FeatureAccordions";
import Features from "../components/Features";
import Hero from "../components/Hero";
import Integration from "../components/Integration";
import IntegrationApps from "../components/IntegrationApps";
import Layout from "../components/layout";
import Platforms from "../components/Platforms";
import Seo from "../components/seo";
import Steps from "../components/Steps";
import TemplateSection from "../components/TemplateSection";
import ViewNotification from "../components/ViewNotification";

const IndexPage = () => {
  return (
    <Layout>
      <Seo
        title="Free Form API and Serveless Form Backend Solution"
        description="Powerful Form API and Serverless Form Backend to receive email notifications with message data of form submissions that you can also connect with your favorite apps & integrations 🚀"
        metaImage="https://cdn-tailgrids.b-cdn.net/formbold-prev.jpg"
      />
      <Hero />
      <Features />
      <FeatureAccordions />
      <ViewNotification />
      <Steps />
      <Platforms />
      <Integration />
      <IntegrationApps />
      {/* <Testimonials />
      <Clients /> */}
      <CallToAction />
      <TemplateSection />
    </Layout>
  );
};

export default IndexPage;
