import React from "react";
import stepOneImage from "./../images/steps/step-1.svg";
import stepTwoImage from "./../images/steps/step-2.svg";
import stepThreeImage from "./../images/steps/step-3.svg";
import stepFourImage from "./../images/steps/step-4.svg";
import SectionTitle from "./SectionTitle";

const circle1 = (
  <svg
    width="71"
    height="36"
    viewBox="0 0 71 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M-4.46544e-05 0.295579C-4.42492e-05 4.93074 0.91292 9.52052 2.68672 13.8029C4.46052 18.0852 7.06042 21.9762 10.338 25.2538C13.6155 28.5313 17.5066 31.1312 21.7889 32.905C26.0712 34.6788 30.661 35.5918 35.2962 35.5918C39.9314 35.5918 44.5211 34.6788 48.8035 32.905C53.0858 31.1312 56.9768 28.5313 60.2544 25.2538C63.5319 21.9762 66.1318 18.0852 67.9056 13.8029C69.6794 9.52052 70.5924 4.93074 70.5924 0.295569L56.442 0.295574C56.442 3.07249 55.8951 5.82221 54.8324 8.38774C53.7697 10.9533 52.2121 13.2844 50.2486 15.248C48.285 17.2115 45.9539 18.7691 43.3884 19.8318C40.8228 20.8945 38.0731 21.4414 35.2962 21.4414C32.5193 21.4414 29.7695 20.8945 27.204 19.8318C24.6385 18.7691 22.3074 17.2115 20.3438 15.248C18.3802 13.2844 16.8226 10.9533 15.7599 8.38775C14.6973 5.82222 14.1503 3.07249 14.1503 0.295577L-4.46544e-05 0.295579Z"
      fill="#6A64F1"
    />
  </svg>
);

const circle2 = (
  <svg
    width="36"
    height="71"
    viewBox="0 0 36 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.296058 70.5924C4.93122 70.5924 9.521 69.6795 13.8033 67.9057C18.0857 66.1319 21.9767 63.532 25.2543 60.2544C28.5318 56.9769 31.1317 53.0858 32.9055 48.8035C34.6793 44.5212 35.5923 39.9314 35.5923 35.2962C35.5923 30.6611 34.6793 26.0713 32.9055 21.7889C31.1317 17.5066 28.5318 13.6156 25.2543 10.338C21.9767 7.06047 18.0857 4.46056 13.8033 2.68676C9.521 0.912962 4.93122 -1.74546e-06 0.296057 -1.54285e-06L0.29606 14.1504C3.07297 14.1504 5.8227 14.6973 8.38823 15.76C10.9538 16.8227 13.2849 18.3803 15.2484 20.3438C17.212 22.3074 18.7696 24.6385 19.8323 27.2041C20.895 29.7696 21.4419 32.5193 21.4419 35.2962C21.4419 38.0731 20.895 40.8229 19.8323 43.3884C18.7696 45.9539 17.212 48.285 15.2484 50.2486C13.2849 52.2122 10.9538 53.7698 8.38823 54.8325C5.8227 55.8951 3.07297 56.4421 0.296058 56.4421L0.296058 70.5924Z"
      fill="#6A64F1"
    />
  </svg>
);

const circle3 = (
  <svg
    width="58"
    height="59"
    viewBox="0 0 58 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="29"
      cy="29.5918"
      r="28"
      fill="#6A64F1"
      stroke="#6A64F1"
      stroke-width="2"
    />
    <rect
      x="12.8889"
      y="13.4805"
      width="32.2222"
      height="32.2222"
      rx="16"
      fill="white"
    />
    <path
      d="M26.2363 38L20 32.0058L22.842 29.2741L26.2363 32.5463L36.158 23L39 25.7317L26.2363 38Z"
      fill="#6A64F1"
    />
  </svg>
);

const stepsData = [
  {
    id: 1,
    title: "01. Register & Login",
    desc:
      "Create account and login to FormBold, you will be redirected to user dashboard where you can create forms to get unique form endpoint.",
    image: stepOneImage,
    circleShape: circle1,
  },
  {
    id: 2,
    title: "02. Copy Your Form Endpoint",
    desc:
      "When you create a form from user dashboard, you will immediately get an unique form endpoint URL for your form you just created.",
    image: stepTwoImage,
    circleShape: circle2,
    right: true,
  },
  {
    id: 3,
    title: "03. Paste Endpoint URL",
    desc:
      "Paste your form endpoint URL as action value to start collecting submissions and make your form dynamic immediately.",
    image: stepThreeImage,
    circleShape: circle1,
  },
  {
    id: 4,
    title: "04. Done! and Ready to Use",
    desc:
      "Its done! your form is ready to collect submissions and its fully dynamic. You will recieve notifications of form submissions via email and apps you connected also can see submission data from your account.",
    image: stepFourImage,
    right: true,
  },
];

const dottedShape = (
  <svg
    width="95"
    height="120"
    viewBox="0 0 95 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="1.49626" cy="1.49626" r="1.49626" fill="#6A64F1" />
    <circle cx="1.4965" cy="14.6642" r="1.49626" fill="#6A64F1" />
    <circle cx="1.49638" cy="27.8302" r="1.49626" fill="#6A64F1" />
    <circle cx="1.4965" cy="40.9982" r="1.49626" fill="#6A64F1" />
    <circle cx="1.49638" cy="54.1642" r="1.49626" fill="#6A64F1" />
    <circle cx="1.49638" cy="79.6017" r="1.49626" fill="#6A64F1" />
    <circle cx="1.4965" cy="105.637" r="1.49626" fill="#6A64F1" />
    <circle cx="1.49626" cy="67.0334" r="1.49626" fill="#6A64F1" />
    <circle cx="1.49626" cy="92.4689" r="1.49626" fill="#6A64F1" />
    <circle cx="1.4965" cy="118.504" r="1.49626" fill="#6A64F1" />
    <circle cx="14.6632" cy="1.49626" r="1.49626" fill="#6A64F1" />
    <circle cx="14.6635" cy="14.6642" r="1.49626" fill="#6A64F1" />
    <circle cx="14.6637" cy="27.8302" r="1.49626" fill="#6A64F1" />
    <circle cx="14.6636" cy="40.9982" r="1.49626" fill="#6A64F1" />
    <circle cx="14.6632" cy="54.1642" r="1.49626" fill="#6A64F1" />
    <circle cx="14.6634" cy="79.6017" r="1.49626" fill="#6A64F1" />
    <circle cx="14.6635" cy="105.637" r="1.49626" fill="#6A64F1" />
    <circle cx="14.6632" cy="67.0334" r="1.49626" fill="#6A64F1" />
    <circle cx="14.6632" cy="92.4689" r="1.49626" fill="#6A64F1" />
    <circle cx="14.6635" cy="118.504" r="1.49626" fill="#6A64F1" />
    <circle cx="27.8302" cy="1.49626" r="1.49626" fill="#6A64F1" />
    <circle cx="67.0324" cy="1.49626" r="1.49626" fill="#6A64F1" />
    <circle cx="27.8305" cy="14.6642" r="1.49626" fill="#6A64F1" />
    <circle cx="67.0326" cy="14.6642" r="1.49626" fill="#6A64F1" />
    <circle cx="27.8307" cy="27.8302" r="1.49626" fill="#6A64F1" />
    <circle cx="67.0325" cy="27.8302" r="1.49626" fill="#6A64F1" />
    <circle cx="27.8301" cy="40.9982" r="1.49626" fill="#6A64F1" />
    <circle cx="67.0322" cy="40.9982" r="1.49626" fill="#6A64F1" />
    <circle cx="27.8309" cy="54.1642" r="1.49626" fill="#6A64F1" />
    <circle cx="67.0329" cy="54.1642" r="1.49626" fill="#6A64F1" />
    <circle cx="27.8305" cy="79.6017" r="1.49626" fill="#6A64F1" />
    <circle cx="67.0325" cy="79.6017" r="1.49626" fill="#6A64F1" />
    <circle cx="27.8306" cy="105.637" r="1.49626" fill="#6A64F1" />
    <circle cx="67.0326" cy="105.637" r="1.49626" fill="#6A64F1" />
    <circle cx="27.8307" cy="67.0334" r="1.49626" fill="#6A64F1" />
    <circle cx="67.0329" cy="67.0334" r="1.49626" fill="#6A64F1" />
    <circle cx="27.8307" cy="92.4689" r="1.49626" fill="#6A64F1" />
    <circle cx="67.0325" cy="92.4689" r="1.49626" fill="#6A64F1" />
    <circle cx="27.8305" cy="118.504" r="1.49626" fill="#6A64F1" />
    <circle cx="67.0326" cy="118.504" r="1.49626" fill="#6A64F1" />
    <circle cx="40.9977" cy="1.49626" r="1.49626" fill="#6A64F1" />
    <circle cx="80.1995" cy="1.49626" r="1.49626" fill="#6A64F1" />
    <circle cx="40.9975" cy="14.6642" r="1.49626" fill="#6A64F1" />
    <circle cx="80.1998" cy="14.6642" r="1.49626" fill="#6A64F1" />
    <circle cx="40.9977" cy="27.8302" r="1.49626" fill="#6A64F1" />
    <circle cx="80.1999" cy="27.8302" r="1.49626" fill="#6A64F1" />
    <circle cx="40.9975" cy="40.9982" r="1.49626" fill="#6A64F1" />
    <circle cx="80.1998" cy="40.9982" r="1.49626" fill="#6A64F1" />
    <circle cx="40.9974" cy="54.1642" r="1.49626" fill="#6A64F1" />
    <circle cx="80.1995" cy="54.1642" r="1.49626" fill="#6A64F1" />
    <circle cx="40.9977" cy="79.6017" r="1.49626" fill="#6A64F1" />
    <circle cx="80.1996" cy="79.6017" r="1.49626" fill="#6A64F1" />
    <circle cx="40.9976" cy="105.637" r="1.49626" fill="#6A64F1" />
    <circle cx="80.1996" cy="105.637" r="1.49626" fill="#6A64F1" />
    <circle cx="40.9977" cy="67.0334" r="1.49626" fill="#6A64F1" />
    <circle cx="80.1995" cy="67.0314" r="1.49626" fill="#6A64F1" />
    <circle cx="40.9977" cy="92.4689" r="1.49626" fill="#6A64F1" />
    <circle cx="80.1994" cy="92.4689" r="1.49626" fill="#6A64F1" />
    <circle cx="40.9975" cy="118.504" r="1.49626" fill="#6A64F1" />
    <circle cx="80.1996" cy="118.504" r="1.49626" fill="#6A64F1" />
    <circle cx="54.1647" cy="1.49626" r="1.49626" fill="#6A64F1" />
    <circle cx="93.3665" cy="1.49626" r="1.49626" fill="#6A64F1" />
    <circle cx="54.1645" cy="14.6642" r="1.49626" fill="#6A64F1" />
    <circle cx="93.3667" cy="14.6642" r="1.49626" fill="#6A64F1" />
    <circle cx="54.1648" cy="27.8302" r="1.49626" fill="#6A64F1" />
    <circle cx="93.3669" cy="27.8302" r="1.49626" fill="#6A64F1" />
    <circle cx="54.165" cy="40.9982" r="1.49626" fill="#6A64F1" />
    <circle cx="93.3666" cy="40.9982" r="1.49626" fill="#6A64F1" />
    <circle cx="54.1643" cy="54.1642" r="1.49626" fill="#6A64F1" />
    <circle cx="93.3665" cy="54.1642" r="1.49626" fill="#6A64F1" />
    <circle cx="54.1647" cy="79.6017" r="1.49626" fill="#6A64F1" />
    <circle cx="93.3669" cy="79.6017" r="1.49626" fill="#6A64F1" />
    <circle cx="54.1645" cy="105.637" r="1.49626" fill="#6A64F1" />
    <circle cx="93.3666" cy="105.637" r="1.49626" fill="#6A64F1" />
    <circle cx="54.1642" cy="67.0314" r="1.49626" fill="#6A64F1" />
    <circle cx="93.3664" cy="67.0334" r="1.49626" fill="#6A64F1" />
    <circle cx="54.1647" cy="92.4689" r="1.49626" fill="#6A64F1" />
    <circle cx="93.3669" cy="92.4689" r="1.49626" fill="#6A64F1" />
    <circle cx="54.1645" cy="118.504" r="1.49626" fill="#6A64F1" />
    <circle cx="93.3666" cy="118.504" r="1.49626" fill="#6A64F1" />
  </svg>
);

export default function Steps() {
  return (
    <section className="pt-28 bg-white" id="how">
      <div className="container">
        <div className="flex justify-center">
          <div className="w-full">
            <SectionTitle
              title="4 Simple and Easy Steps"
              center
              paragraph="Straight forward and simple steps to make your form dynamic and start recieving submissions via Email, Slack, Telegram and more."
              paraWidth="690"
              margin="mb-20"
            />
          </div>
        </div>

        <div className="flex justify-center">
          <div className="w-full max-w-[970px] mx-auto ">
            {stepsData.map((step, id) => (
              <Step step={step} key={id} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

const Step = (props) => {
  const { title, desc, image, right, circleShape } = props.step;
  return (
    <div className="flex flex-wrap items-center justify-between -mx-4 mb-16">
      <div className="w-full md:w-7/12 lg:w-1/2 px-4">
        <div
          className={`mb-12 md:mb-0 max-w-[460px] ${right && "md:ml-auto"} `}
        >
          <h3 className="font-bold text-black text-2xl sm:text-[32px] md:text-2xl lg:text-[28px] xl:text-[32px] mb-6">
            {title}
          </h3>
          <p className="font-medium text-body-color text-lg md:text-base xl:text-lg">
            {desc}
          </p>
        </div>
      </div>
      <div
        className={`w-full md:w-5/12 lg:w-1/2 px-4 ${
          right && "md:order-first"
        } `}
      >
        <div
          className={`text-center ${right ? "md:text-left" : "md:text-right"}`}
        >
          <div className="inline-flex items-center relative pt-5 pb-6 px-5 z-10">
            <img src={image} alt="steps" />

            <span className="absolute -z-10 bottom-0 right-0">
              {circleShape}
            </span>
            <span className="absolute top-0 left-0 -z-10">{dottedShape}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
