import { Link } from "gatsby";
import React from "react";
import SectionTitle from "./SectionTitle";

const shape1 = (
  <svg
    width="388"
    height="250"
    viewBox="0 0 388 220"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.05"
      d="M203 -28.5L4.87819e-05 250.5L881.5 250.5L881.5 -28.5002L203 -28.5Z"
      fill="url(#paint0_linear_971_6910)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_971_6910"
        x1="60.5"
        y1="111"
        x2="287"
        y2="111"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.520507" stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

const shape2 = (
  <svg
    width="324"
    height="250"
    viewBox="0 0 324 220"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.05"
      d="M203 -28.5L4.87819e-05 250.5L881.5 250.5L881.5 -28.5002L203 -28.5Z"
      fill="url(#paint0_linear_971_6911)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_971_6911"
        x1="60.5"
        y1="111"
        x2="287"
        y2="111"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.520507" stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

const shape3 = (
  <svg
    width="43"
    height="56"
    viewBox="0 0 43 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <circle
        cx="40.9984"
        cy="1.49626"
        r="1.49626"
        transform="rotate(90 40.9984 1.49626)"
        fill="white"
      />
      <circle
        cx="27.8304"
        cy="1.49626"
        r="1.49626"
        transform="rotate(90 27.8304 1.49626)"
        fill="white"
      />
      <circle
        cx="14.6644"
        cy="1.49626"
        r="1.49626"
        transform="rotate(90 14.6644 1.49626)"
        fill="white"
      />
      <circle
        cx="1.49642"
        cy="1.49626"
        r="1.49626"
        transform="rotate(90 1.49642 1.49626)"
        fill="white"
      />
      <circle
        cx="40.9984"
        cy="14.6642"
        r="1.49626"
        transform="rotate(90 40.9984 14.6642)"
        fill="white"
      />
      <circle
        cx="27.8304"
        cy="14.6642"
        r="1.49626"
        transform="rotate(90 27.8304 14.6642)"
        fill="white"
      />
      <circle
        cx="14.6644"
        cy="14.6642"
        r="1.49626"
        transform="rotate(90 14.6644 14.6642)"
        fill="white"
      />
      <circle
        cx="1.49642"
        cy="14.6642"
        r="1.49626"
        transform="rotate(90 1.49642 14.6642)"
        fill="white"
      />
      <circle
        cx="40.9984"
        cy="27.8302"
        r="1.49626"
        transform="rotate(90 40.9984 27.8302)"
        fill="white"
      />
      <circle
        cx="27.8304"
        cy="27.8302"
        r="1.49626"
        transform="rotate(90 27.8304 27.8302)"
        fill="white"
      />
      <circle
        cx="14.6644"
        cy="27.8302"
        r="1.49626"
        transform="rotate(90 14.6644 27.8302)"
        fill="white"
      />
      <circle
        cx="1.49642"
        cy="27.8302"
        r="1.49626"
        transform="rotate(90 1.49642 27.8302)"
        fill="white"
      />
      <circle
        cx="40.9984"
        cy="40.9982"
        r="1.49626"
        transform="rotate(90 40.9984 40.9982)"
        fill="white"
      />
      <circle
        cx="27.8304"
        cy="40.9963"
        r="1.49626"
        transform="rotate(90 27.8304 40.9963)"
        fill="white"
      />
      <circle
        cx="14.6644"
        cy="40.9982"
        r="1.49626"
        transform="rotate(90 14.6644 40.9982)"
        fill="white"
      />
      <circle
        cx="1.49642"
        cy="40.9963"
        r="1.49626"
        transform="rotate(90 1.49642 40.9963)"
        fill="white"
      />
      <circle
        cx="40.9984"
        cy="54.1642"
        r="1.49626"
        transform="rotate(90 40.9984 54.1642)"
        fill="white"
      />
      <circle
        cx="27.8304"
        cy="54.1642"
        r="1.49626"
        transform="rotate(90 27.8304 54.1642)"
        fill="white"
      />
      <circle
        cx="14.6644"
        cy="54.1642"
        r="1.49626"
        transform="rotate(90 14.6644 54.1642)"
        fill="white"
      />
      <circle
        cx="1.49642"
        cy="54.1642"
        r="1.49626"
        transform="rotate(90 1.49642 54.1642)"
        fill="white"
      />
    </g>
  </svg>
);

export default function CallToAction() {
  return (
    <section className="bg-primary relative z-10 overflow-hidden py-16">
      <div className="container">
        <div className="flex flex-wrap items-center -mx-4">
          <div className="w-full lg:w-1/2 px-4">
            <div className="text-center lg:text-left ">
              <SectionTitle
                title="Start building automated serverless forms"
                color="white"
                margin="mb-10 lg:mb-0"
              />
            </div>
          </div>
          <div className="w-full lg:w-1/2 px-4">
            <div className="text-center lg:text-right">
              <Link
                to="/pricing"
                className="bg-white btn !text-black inline-flex items-center justify-center py-4 px-9 mx-auto hover:bg-opacity-90"
              >
                Create Your First Form
              </Link>
            </div>
          </div>
        </div>
      </div>

      <span className="absolute -z-10 top-0 right-0">{shape1}</span>
      <span className="absolute -z-10 top-0 right-0">{shape2}</span>
      <span className="absolute -z-10 top-4 left-4">{shape3}</span>
    </section>
  );
}
