import { Link } from "gatsby";
import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import codeImage from "./../images/hero-code.svg";

export default function Hero() {
  const [isOpen, setOpen] = useState(false);
  return (
    <section
      className="relative pt-[170px] pb-20 z-10 overflow-hidden"
      style={{
        backgroundImage: `linear-gradient(180deg, #FFFFFF 0%, #F5F8FF 100%)`,
      }}
    >
      <div className="container">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full px-4">
            <div className="max-w-[970px] mx-auto text-center">
              <h1 className="max-w-[740px] mx-auto font-extrabold text-3xl sm:text-4xl md:text-[40px] lg:text-4xl xl:text-[40px] leading-snug sm:leading-snug md:leading-snug lg:leading-snug xl:leading-snug text-black mb-5">
                Simple Free Form API and Backend Solution for All Platforms
              </h1>
              <p className="text-base md:text-lg leading-relaxed md:leading-relaxed text-[#636E72] mb-5">
              Make static forms dynamic in seconds and start receiving submissions immediately. FormBold is a powerful form API and serverless form backend solution that works with all platforms and tech stack. Receive email notifications with data of form submissions that you can also, connect with your favorite apps & integrations.
              </p>
              <div className="mb-5 text-center">
                <img
                  src={codeImage}
                  alt="hero-code-smaple"
                  className="mx-auto"
                />
              </div>
              <div className="flex items-center justify-center mb-9">
                <p className="font-semibold text-base text-black">
                  Connect forms with your favourite apps{" "}
                  <a
                    href="#view"
                    className="text-primary inline-flex items-center"
                  >
                    Check how
                    <span>
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.8333 15.528V11.8613H4.65665L4.62915 10.0188H12.8333V6.36133L17.4167 10.9447L12.8333 15.528Z"
                          fill="#6A64F1"
                        />
                      </svg>
                    </span>
                  </a>
                </p>
              </div>
              <div className="flex flex-wrap justify-center mx-[-10px]">
                <div className="px-[10px] mb-8">
                  <Link
                    to="https://app.formbold.com/register"
                    className="bg-primary border border-primary btn py-3 px-8 inline-flex justify-center hover:shadow-card hover:bg-opacity-90"
                  >
                    Create Forms
                  </Link>
                </div>
                <div className="px-[10px] mb-8">
                  {/* <button
                    className="bg-black border border-black btn py-3 px-8 inline-flex items-center justify-center hover:shadow-card hover:bg-opacity-90"
                    onClick={() => setOpen(true)}
                  >
                  </button> */}
                  <a
                    href="#how"
                    className="bg-black border border-black btn py-3 px-8 inline-flex items-center justify-center hover:shadow-card hover:bg-opacity-90"
                    >
                    <span className="pr-2">
                      <svg
                        width="13"
                        height="16"
                        viewBox="0 0 13 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.5 7.13397C13.1667 7.51888 13.1667 8.48112 12.5 8.86602L2 14.9282C1.33333 15.3131 0.499999 14.832 0.499999 14.0622L0.5 1.93782C0.5 1.16802 1.33333 0.686896 2 1.0718L12.5 7.13397Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                    Learn How
                  </a>
                </div>

                {typeof window !== "undefined" && (
                  <ModalVideo
                    channel="youtube"
                    autoplay
                    isOpen={isOpen}
                    videoId="5XQPr4sQDYQ"
                    onClose={() => setOpen(false)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
