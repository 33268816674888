import React from "react";
import image from "./../images/view-notification.svg";

export default function ViewNotification() {
  return (
    <section id="view" className="pt-28">
      <div className="container">
        <div className="pb-28 border-b border-[#D7D7D7]">
          <div className="flex flex-wrap items-center -mx-4">
            <div className="w-full lg:w-1/2 px-4">
              <div className="max-w-[550px] mb-12 lg:mb-0">
                <h2 className="font-bold text-black text-3xl sm:text-4xl md:text-[40px] lg:text-4xl xl:text-[40px] !leading-tight mb-6">
                Connect apps and get notified immediately.
                </h2>
                <p className="font-medium text-base text-body-color max-w-[500px]">
                When a new submission is received, get notified immediately with submission data. Connect your form with apps and integrations you like and never miss a submission again. Currently, we have - Slack, Telegram, Zapier, and Webhook integrations and more integrations are coming soon!
                </p>
              </div>
            </div>

            <div className="w-full lg:w-1/2 px-4">
              <div>
                <img src={image} alt="Illustration" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
